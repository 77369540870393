<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Управление пользователями

        vuecrud(selectedtable = "user", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")
          template(v-slot:popupHeader="binds")
            div.datatable-modal-container(style="background-color:#3c4b64; color: white; padding: 16px")
              h5.modal-title ДОБАВЛЕНИЕ ПОЛЬЗОВАТЕЛЯ
          //template(v-slot:Popup="popupInfo")

</template>

<script>

export default {
  name: 'Users',
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: true,
        canEdit: true,
        canDelete: true
      }

    }
  },
  methods: {
    columnsupdated: function (cols) {

      cols.set('id', {label: "ID", hasFilter: true, type: 'fixed', isShowOnPopup: false, isEdit: false });
      cols.delete('password_hash', {label: "Пароль", hasFilter: false, type: 'text' });
      cols.delete('created_at', {label: "111", hasFilter: false, isShowOnPopup: false, isEdit: false });
      cols.delete('updated_at', {label: "Пароль", hasFilter: false, isShowOnPopup: false, isEdit: false });
      cols.set('username', {label: "Логин", hasFilter: true, type: 'text' });
      cols.set('name', {label: "ФИО", hasFilter: true, type: 'text' });
      cols.set('email', {label: "Email", hasFilter: true, type: 'text'});
      cols.set('tel', {label: "Телефон", hasFilter: true, type: 'text'});
      cols.delete('status', {label: "Статус", hasFilter: true, type: 'text', isShowOnPopup: false, isEdit: false});
      cols.delete('brands', {label: "Бренды", hasFilter: true, type: 'text', isShowOnPopup: true, isEdit: true});
      cols.set('partner', {label: "Партнёр", hasFilter: false, type: 'text', isShowOnPopup: true, isEdit: true, isLoadKeys: true, selectRepresentAs: (column) => '# ' + column.id + ' ' + column.name});
      cols.set('role', {label: "Уровень доступа", hasFilter: true, type: 'text', isShowOnPopup: true, isEdit: true, isLoadKeys: true, selectRepresentAs: (column) => column.name});
      //cols.set('regdir', {label: "Региональный директор", hasFilter: true, type: 'text', isShowOnPopup: true, isEdit: true, isLoadKeys: true, selectRepresentAs: (column) => '# ' + column.id + ' ' + column.name});
      cols.delete('franchise', {label: "Франшиза", hasFilter: false, isShowOnPopup: false, isEdit: false});

      return cols;

    },

    gotoItem(row) {
      this.$router.push({name: 'Good', params:{id: row.id}});
    }

  }
}
</script>
